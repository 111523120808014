import React from 'react'
import 'react-day-picker/style.css'
import { FontProps } from 'common/utils/styleUtilsNew'
import { PaddingType } from 'common/types/styleTypes'
import { DayPicker } from './day-picker'
import { AvailabilitySlot, EventInterface } from './calendar-interface'
import { TimeSlots } from './time-slots'
import { EventInfo } from './event-info'
import { BookingCalendarContainerUi } from './ui/booking-calendar-container-ui'

interface CommonBookingCalendarProps {
  availabilitySlots: AvailabilitySlot[]

  availableSlotBackgroundColor: string
  selectedSlotBackgroundColor: string
  titlesColor?: string
  mobileTitlesColor?: string
  padding: PaddingType
  mobilePadding: PaddingType

  selectedDate?: Date
  onDateSelect?: (date: Date) => void
  selectedTimeSlot?: number
  onTimeSlotSelect?: (timeSlot: number) => void
  selectedTimeZone: string
  onTimeZoneSelect?: (timeZone: string) => void
  selectedMonth?: Date
  onMonthSelect?: (month: Date) => void

  event?: EventInterface
  isLoading?: boolean
}

function CommonBookingCalendar({
  availabilitySlots,
  selectedDate,
  selectedMonth,
  onMonthSelect,
  onDateSelect,
  selectedTimeSlot,
  onTimeSlotSelect,
  selectedTimeZone,
  onTimeZoneSelect,
  event,
  isLoading,

  availableSlotBackgroundColor,
  selectedSlotBackgroundColor,
  titlesColor,
  mobileTitlesColor,
  color,
  mobileColor,

  ...stylesProps
}: CommonBookingCalendarProps & FontProps) {
  return (
    <BookingCalendarContainerUi {...stylesProps}>
      <EventInfo
        {...event}
        selectedTimeZone={selectedTimeZone}
        onTimeZoneSelect={onTimeZoneSelect}
        titlesColor={titlesColor}
        mobileTitlesColor={mobileTitlesColor}
        color={color}
        mobileColor={mobileColor}
        isLoading={isLoading}
      />
      <DayPicker
        selectedDate={selectedDate}
        onDateSelect={onDateSelect}
        selectedMonth={selectedMonth}
        onMonthSelect={onMonthSelect}
        isLoading={isLoading}

        availableSlotBackgroundColor={availableSlotBackgroundColor}
        selectedSlotBackgroundColor={selectedSlotBackgroundColor}
        color={color}
        mobileColor={mobileColor}
        titlesColor={titlesColor}
        mobileTitlesColor={mobileTitlesColor}
        availabilitySlots={availabilitySlots}
      />
      {selectedDate && (
        <TimeSlots
          availabilitySlots={availabilitySlots}
          onTimeSlotSelect={onTimeSlotSelect}
          selectedTimeSlot={selectedTimeSlot}
          selectedSlotBackgroundColor={selectedSlotBackgroundColor}
          selectedDate={selectedDate}
        />
      )}
    </BookingCalendarContainerUi>
  )
}

export default CommonBookingCalendar
