import React from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'client/components/core/Sidebar/components/Settings/components/ReactSelect/ReactSelect'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import Image from '../../ImageNew'
import { EventInterface } from '../calendar-interface'
import {
  EventLocationTypeEnum,
  EventTimeZoneTypeEnum,
  getReadableEventLocation,
} from '../constants'
import { ClockIcon } from '../ui/icons/ClockIcon'
import { WorldIcon } from '../ui/icons/WorldIcon'
import { getAllTimeZones } from '../utils'
import { EventInfoSkeleton } from './event-info-skeleton'
import { EventInfoWrapperUi } from './ui/event-info-wrapper-ui'
import { EventTimeZoneSelectWrapper } from './ui/event-timeZone-select-wrapper'

interface EventInfoProps extends Partial<EventInterface> {
  selectedTimeZone: string
  onTimeZoneSelect?: (timeZone: string) => void
  titlesColor?: string
  mobileTitlesColor?: string
  color?: string
  mobileColor?: string
  isLoading?: boolean
}

const EDITOR_DURATION_PLACEHOLDER = 30

export const EventInfo = ({
  name,
  eventDuration,
  locations,
  selectedTimeZone,
  onTimeZoneSelect,
  displayTimezoneType,
  hostName,
  hostAvatarDataFileId,
  titlesColor,
  mobileTitlesColor,
  color,
  mobileColor,
  isLoading,
}: EventInfoProps) => {
  const timzonesList = getAllTimeZones()

  const { t } = useTranslation()
  const { isMobile } = useDeviceModeContext()

  if (isLoading) return <EventInfoSkeleton />

  return (
    <EventInfoWrapperUi
      titlesColor={isMobile ? mobileTitlesColor : titlesColor}
      color={isMobile ? mobileColor : color}
    >
      <div>
        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          {/* <div style={{ borderRadius: '50%', overflow: 'hidden', height: '60px', objectFit: 'cover' }}>
            <Image
              src={}
              alt={hostName}
              width={60}
              alignSelf='center'
              attrId={''}
            />
          </div> */}
          <span>{hostName}</span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 30,
            margin: '40px 0',
          }}
        >
          <div>
            <span color={color}>
              {t('components.core.calendar.event_name')}
            </span>
            <p>{name || t('components.core.calendar.empty_event_name')}</p>
          </div>
          <div>
            <span color={color}>
              {t('components.core.calendar.event_time')}
            </span>
            <p style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <ClockIcon /> {eventDuration || EDITOR_DURATION_PLACEHOLDER}{' '}
              {t('components.core.calendar.min')}
            </p>
          </div>
          <div>
            <span color={color}>
              {t('components.core.calendar.event_location.name')}
            </span>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {!locations && (
                <p>
                  {t(
                    getReadableEventLocation(
                      EventLocationTypeEnum.OnlineMeeting,
                    ),
                  )}
                </p>
              )}
              {locations?.map(({ locationType }) => (
                <p key={locationType}>
                  {t(getReadableEventLocation(locationType))}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span color={color}>{t('components.core.calendar.timezone')}</span>
        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
          <WorldIcon />
          <EventTimeZoneSelectWrapper>
            <Select
              isMulti={false}
              isDisabled={
                !onTimeZoneSelect ||
                displayTimezoneType === EventTimeZoneTypeEnum.Fixed
              }
              options={timzonesList}
              selectedOption={{
                label: selectedTimeZone,
                value: selectedTimeZone,
              }}
              update={timeZone => {
                if (timeZone && onTimeZoneSelect)
                  onTimeZoneSelect?.(timeZone.value)
              }}
              styles={{
                control: provided => ({
                  ...provided,
                  border: 0,
                  backgroundColor: 'transparent',
                }),
                indicatorSeparator: () => ({}),
              }}
            />
          </EventTimeZoneSelectWrapper>
        </div>
      </div>
    </EventInfoWrapperUi>
  )
}
