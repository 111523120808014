import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import CommonBookingCalendar from 'common/components/entities/Calendar'
import { EventTimeZoneTypeEnum } from 'common/components/entities/Calendar/constants'
import { useEvent } from 'common/components/entities/Calendar/hooks/use-event'
import { getStartEndDates } from 'common/components/entities/Calendar/utils'
import { BookingCalendarInterface } from 'common/types/entities/BookingCalendarInterface'
import { getLocalTimeZone } from 'common/utils/date-utils'
import { changeBookingField } from 'publisher/actions/optInActions'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import { useEventTimeSlots } from 'publisher/hooks/use-event-time-slots'
import { optInSelectors, usePage, useTypedSelector } from 'publisher/store'
import { BookingFieldsSlug } from 'publisher/store/optIn/optInStateInterface'
import pageSelectors from 'publisher/store/page/pageSelectors'
import FieldErrors from '../FieldErrors'

interface BookingCalendarProps {
  entity: BookingCalendarInterface
}

function BookingCalendar({ entity }: BookingCalendarProps) {
  const dispatch = useDispatch()

  const selectedDate = useTypedSelector(state =>
    optInSelectors.getBookingFieldValue(state.optIn, 'date'),
  )
  const selectedTimeSlot = useTypedSelector(state =>
    optInSelectors.getBookingFieldValue(state.optIn, 'timeSlot'),
  )
  const selectedTimeZone = useTypedSelector(state =>
    optInSelectors.getBookingFieldValue(state.optIn, 'timeZone'),
  )
  const errors = useTypedSelector(state => state.optIn.errors.bookingFields)

  const handleChangeField = <T,>(slug: BookingFieldsSlug, value: T) => {
    dispatch(changeBookingField(slug, value))
  }

  const { event, isFetching: isEventFetching } = useEvent({
    eventId: entity.eventId,
    scope: 'publisher',
  })
  const { timeSlots = [], isFetching: isTimeSlotsFetching } = useEventTimeSlots()

  useEffect(() => {
    if (event) {
      handleChangeField('eventId', event.id!)
      handleChangeField('ownerId', event.ownerId)
      handleChangeField('eventLocationId', event.locations?.[0]?.id)
      handleChangeField(
        'timeZone',
        event.displayTimezoneType === EventTimeZoneTypeEnum.Local
          ? getLocalTimeZone()
          : event.displayTimezone || getLocalTimeZone(),
      )
    }
  }, [event])

  const onMonthSelect = (month: Date) => {
    const { startDate, endDate } = getStartEndDates(month)
    handleChangeField('startDate', startDate)
    handleChangeField('endDate', endDate)
  }

  const globalFontSize = usePage(pageSelectors.getGlobalTextFontSize)
  const globalMobileFontSize = usePage(
    pageSelectors.getGlobalMobileTextFontSize,
  )
  const globalFontFamily = usePage(pageSelectors.getGlobalTextFontFamily)

  if (!entity.eventId) return <></>

  return (
    <StyleWrapper
      mobileMargin={entity.mobileMargin}
      margin={entity.margin}
      appearance={entity.appearance}
    >
      <CommonBookingCalendar
        selectedDate={selectedDate}
        onDateSelect={date => {
          handleChangeField('date', date)
          handleChangeField('timeSlot', '')
        }}
        selectedTimeSlot={selectedTimeSlot}
        onTimeSlotSelect={timeSlot => handleChangeField('timeSlot', timeSlot)}
        selectedTimeZone={selectedTimeZone}
        onTimeZoneSelect={tz => {
          if (event?.displayTimezoneType !== EventTimeZoneTypeEnum.Fixed) {
            handleChangeField('timeZone', tz)
          }
        }}
        onMonthSelect={onMonthSelect}
        availabilitySlots={timeSlots}
        event={event}
        availableSlotBackgroundColor={entity.availableSlotBackgroundColor}
        selectedSlotBackgroundColor={entity.selectedSlotBackgroundColor}
        titlesColor={entity.titlesColor}
        mobileTitlesColor={entity.mobileTitlesColor}
        color={entity.color}
        mobileColor={entity.mobileColor}
        fontSize={globalFontSize}
        mobileFontSize={globalMobileFontSize}
        fontFamily={globalFontFamily}
        mobileFontFamily={globalFontFamily}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        isLoading={isEventFetching || isTimeSlotsFetching}
      />
      {Object.values(errors).map((errors, i) => (
        <FieldErrors key={i} errors={errors} />
      ))}
    </StyleWrapper>
  )
}

export default BookingCalendar
