import { PaddingType } from 'common/types/styleTypes'
import { fontCss, FontProps, paddingsCss } from 'common/utils/styleUtilsNew'
import styled, { css } from 'styled-components'

interface BookingCalendarContainerUiProps {
  padding: PaddingType
  mobilePadding: PaddingType
}

export const BookingCalendarContainerUi = styled.div<BookingCalendarContainerUiProps & FontProps>`
  width: fit-content;
  height: 100%;
  background-color: white;
  display: flex;
  padding: 40px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  ${paddingsCss}
  ${fontCss}

  ${p => p.theme.isEditorMobileMode && css`
    padding: 5px;
    flex-direction: column;
  `}
  ${p => p.theme.phone} {
    padding: 5px;
    flex-direction: column;
  }
`
